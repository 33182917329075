.tooltipLink {
    text-decoration: underline;
    color: white;
}

.tooltipLink:hover {
    /* color: grey; */
}

.mobileResponsive {
    max-width: 450px;

    @media (max-width: 768px) {
        max-width: 300px;
    }

    @media (max-width: 350px) {
        max-width: 200px;
    }
}

.tooltipWrapper {
}

.closeButton {
    text-align: right;
    z-index: 1;
    font-size: 18px;
}